import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

let usuario = localStorage.getItem('user');
const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  user: usuario ? JSON.parse(usuario) : {}
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  saveUser: (state, user) => {
    state.user = user;
  },
  deleteUser: (state) => {
    state.user = {};
  }
}

const actions = {
  async saveUser({ commit }, usuario) {
    commit('saveUser', usuario);
  },
  async deleteUser({ commit }) {
    commit('deleteUser', usuario);
  },

}

export default new Vuex.Store({
  state,
  mutations,
  actions
})